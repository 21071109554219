import rpc from "@/src/rpc"
import { useRef, useState } from "react"
import Modal from "./Modal"
import { useMessage } from "@/src/useMessage"
import useDeviceInfo from "@/src/useDeviceInfo"


export default function SupportRequestModal () {

  const textareaRef = useRef()

  const [submitted, setSubmitted] = useState()
  const [error, setError] = useState()
  const [message, setMessage] = useState('')
  const [supportRequestVisible, setSupportRequestVisible] = useState(false)
  const [context, setContext] = useState({})


  const {width} = useDeviceInfo()
  const tallBox = width > 400

  useMessage('help', options => {
    setContext(options?.context || {})
    setMessage(options?.message || '')
    setSupportRequestVisible(true)
    setTimeout(() => textareaRef.current?.focus(), 100)
  })


  async function handleSubmit (e) {
    e.preventDefault()
    if (!message) return

    context.url = window.location.href
    try {
      await rpc('users.requestHelp', {message, context}, {throwErrors: true})
      setSubmitted(true)
      setError('')
      setMessage('')
    } catch (err) {
      setError(err.message || '')
      console.error(err)
    }
  }


  function handleClose (event) {
    event.preventDefault()
    setMessage('')
    setSubmitted(false)
    setError('')
    setSupportRequestVisible(false)
  }


  return <>
    <Modal visible={supportRequestVisible}>
      <div className="p-4 sm_p-5 max-width-350">
        <section className="text-center">
          { submitted &&
            <div className="absolute-full z-1 bg-white flex-col gap-4 flex-align-center flex-justify-center gap-2 p-4">
              <h2 className="text-2xl bold">
                Message Sent 👍
              </h2>
              <p>
                We'll be in touch soon.
              </p>
              <button className="button is-primary" style={{width: 200}} onClick={handleClose}>
                Ok
              </button>
            </div>
          }

          <h1 className='text-2xl bold mb-2'>
            Support Request
          </h1>

          <form className="flex-col gap-4" onSubmit={handleSubmit}>
            <p className="gray">
              We look forward to helping you.
            </p>

            { error &&
              <p className="error-message">
                😩 Message failed. Please try again.
              </p>
            }

            <textarea
              ref={textareaRef}
              value={message}
              onChange={e => setMessage(e.target.value)}
              className="textarea"
              placeholder="Type your question or comment here"
              rows={tallBox ? 8 : 4}
            />

            <div className="flex-row gap-2 flex-center">
              <button className="button is-primary" disabled={!message}>
                Send Message
              </button>
              <button className="button" onClick={handleClose}>
                Cancel
              </button>
            </div>

            <hr className="my-1"/>

            <div className="gray text-sm">
              Or if you prefer the "old fashion" way, <span className="hidden sm_contents">you can</span> email us at <a className="link" href="mailto:support@socialjazz.com">
                support@socialjazz.com
              </a>
            </div>
          </form>

        </section>
      </div>
    </Modal>
  </>
}
